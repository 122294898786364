// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-portfolio-js": () => import("./../../../src/pages/en/portfolio.js" /* webpackChunkName: "component---src-pages-en-portfolio-js" */),
  "component---src-pages-en-snippets-js": () => import("./../../../src/pages/en/snippets.js" /* webpackChunkName: "component---src-pages-en-snippets-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-snippets-js": () => import("./../../../src/pages/snippets.js" /* webpackChunkName: "component---src-pages-snippets-js" */),
  "component---src-templates-drupal-node-article-js": () => import("./../../../src/templates/Drupal_NodeArticle.js" /* webpackChunkName: "component---src-templates-drupal-node-article-js" */),
  "component---src-templates-drupal-node-page-js": () => import("./../../../src/templates/Drupal_NodePage.js" /* webpackChunkName: "component---src-templates-drupal-node-page-js" */),
  "component---src-templates-drupal-node-portfolio-js": () => import("./../../../src/templates/Drupal_NodePortfolio.js" /* webpackChunkName: "component---src-templates-drupal-node-portfolio-js" */),
  "component---src-templates-drupal-node-snippet-js": () => import("./../../../src/templates/Drupal_NodeSnippet.js" /* webpackChunkName: "component---src-templates-drupal-node-snippet-js" */),
  "component---src-templates-drupal-node-webform-js": () => import("./../../../src/templates/Drupal_NodeWebform.js" /* webpackChunkName: "component---src-templates-drupal-node-webform-js" */)
}

